<template>
  <div>    
    <div class="widget-container">

        <DxForm
            id="form"
            :form-data="state.formData"
            label-mode='outside'
            :read-only='false'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'
            ref="formMaster"
        >

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

        <DxGroupItem caption="Βασικά Στοιχεία" :col-span="3" :col-count="3">
            <DxSimpleItem 
                data-field="surname"
                :is-required="true"
            >
                <DxLabel text="Επίθετο"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="name"
                :is-required="true"
            >
                <DxLabel text="Όνομα"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="employment_capacity_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_employment_capacities,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Ιδιότητα"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="employment_relationship_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_employment_relationships,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id',
                    onSelectionChanged: this.onEmploymentRelationshipSelectionChange,
                }"
            >
                <DxLabel text="Σχέση εργασίας"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="enddate"
                :is-required="!isDateBoxReadOnly"
                editor-type="dxDateBox"
                :disabled="isDateBoxReadOnly"
                :editor-options="{  //pickerType: 'rollers',
                                    type: 'date',
                                    displayFormat: 'dd/MM/yyyy',
                                    dateSerializationFormat: 'yyyy-MM-dd',
                                    invalidDateMessage: 'Λάθος Ημερομηνία',
                                    showClearButton: true,
                                    //min: new Date(),
                                    max: new Date(2030, 11, 31)
                                }"
            >
                <DxLabel text="Ημερομηνία Λήξης Υπηρεσίας"/>
            </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem caption="Τοποθέτηση" :col-span="3" :col-count="3">
            <DxSimpleItem
                data-field="building_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    searchEnabled: true,
                    dataSource: this.state.LookupCustomDS_sims_buildings,
                    displayExpr: 'name',
                    valueExpr:'id',
                    onValueChanged: this.onBuildingChange
                }"
            >
                <DxLabel text="Κτίριο"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="directorate_service_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_directorate_services,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id', 
                    onValueChanged: this.onDirectorateServicesChange
                }"
            >
                <DxLabel text="Διεύθυνση"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="directorate_service_department_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_directorate_service_departments,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id', 
                    onValueChanged: this.onDirectorateServicesDepartmentChange,
                    
                }"
            >
                <DxLabel text="Τμήμα"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="directorate_service_department_office_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_directorate_service_department_offices,
                    searchEnabled: true,
                    displayExpr: 'name',
                    showClearButton: true,
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Γραφείο"/>
            </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Στοιχεία Επικοινωνίας" :col-span="3" :col-count="3">
            <DxSimpleItem
                data-field="phone_telephony_numbers_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCascadeCustomDS_sims_telephony_numbers_VIEW,
                    searchEnabled: true,
                    displayExpr: 'phone_no',
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Σταθερό Τηλέφωνο"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="mobile_telephony_numbers_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_telephony_numbers_filter_mobile,
                    searchEnabled: true,
                    displayExpr: 'phone_no',
                    valueExpr: 'id'
                }"
            >
                <DxLabel text="Υπηρεσιακό Κινητό Тηλέφωνο"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="phone_no_1"
            >
                <DxLabel text="Προσωπικό Κινητό Τηλέφωνο"/>
            </DxSimpleItem>

            <!--
            <DxSimpleItem
                data-field="phone_no_2"
            >
                <DxLabel text="Άλλο Τηλέφωνο"/>
            </DxSimpleItem>
            -->

            <DxSimpleItem
                data-field="email_1"
            >
                <DxLabel text="Υπηρεσιακό Еmail"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="email_2"
            >
                <DxLabel text="Προσωπικό Еmail"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="email_3"
            >
                <DxLabel text="Άλλος Τρόπος Επικοινωνίας"/>
            </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem caption="Επιπλέον Πληροφορίες">
            <DxSimpleItem
                data-field="description"
                editor-type="dxTextArea"
            >
                <DxLabel :visible="false" text=""/>
            </DxSimpleItem>
        </DxGroupItem>

      </DxForm>

      <div class="dx-fieldset-header">Στοιχεία Πρόσβασης Υπαλλήλου</div>
      <div class="dx-fieldset">
        <DxTabPanel ref="tabPanelGrids" @selectionChanged="onSelectionChanged">
            <DxItem title="Email" id="emails">
                <DxDataGrid
                    :data-source="state.CustomStoreDetail_sims_employment_staff_access_office_emails"
                    :key-expr="id"
                    :columns="columns"
                    :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                    :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                    :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                    :style="{ 'max-width': maxWidth }"
                    ref="gridDetail_sims_employment_staff_access_office_emails"
                    :word-wrap-enabled="true"

                    @exporting="onExporting"
                    @rowClick="openForm"
                    @init-new-row="onInitNewRow"
                    @row-validating="onRowValidating"
                    
                    @editing-start="logEvent('EditingStart')"
                    @row-inserting="logEvent('RowInserting')"
                    @row-inserted="logEvent('RowInserted')"
                    @row-updating="logEvent('RowUpdating')"
                    @row-updated="logEvent('RowUpdated')"
                    @row-removing="logEvent('RowRemoving')"
                    @row-removed="logEvent('RowRemoved')"
                    @saving="logEvent('Saving')"
                    @saved="logEvent('Saved')"
                    @edit-canceling="logEvent('EditCanceling')"
                    @edit-canceled="logEvent('EditCanceled')"
                >
                    <DxPaging :enabled="true" :page-size="5"/>

                    <DxFilterRow :visible="false"/>
                    <DxHeaderFilter :visible="true"/>
                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/>
                    <DxGroupPanel :visible="false"/>
                    <DxSorting mode="multiple"/>
                    <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"/>

                    <DxColumn data-field="name" caption="Πάροχος">
                        <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                    </DxColumn>

                    <DxColumn data-field="description" caption="URL"/>

                    <DxColumn data-field="username" caption="Όνομα Χρήστη">
                        <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                    </DxColumn>

                    <DxColumn data-field="password" caption="Κωδικός Πρόσβασης" :editor-type="'dxTextBox'" :cell-template="(cellElement) => cellElement.textContent = '••••••••'">
                        <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                    </DxColumn>
                </DxDataGrid>
            </DxItem>

        <DxItem title="Εκτυπωση" id="printing">
            <DxDataGrid
                :data-source="state.CustomStoreDetail_sims_employment_staff_access_office_printing"
                :key-expr="id"          
                :columns="columns"
                :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                :style="{ 'max-width': maxWidth }"
                ref="gridDetail_sims_employment_staff_access_office_printing"
                :word-wrap-enabled="true"

                @exporting="onExporting"
                @rowClick="openForm"
                @init-new-row="onInitNewRow"
                @row-validating="onRowValidating">
            >
                <DxPaging :enabled="true" :page-size="5"/>

                <DxFilterRow :visible="false"/>
                <DxHeaderFilter :visible="true"/>
                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/>
                <DxGroupPanel :visible="false"/>
                <DxSorting mode="multiple"/>
                <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"/>

                <DxColumn data-field="name" caption="Συσκευή Εκτύπωσης">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="username" caption="Όνομα Χρήστη">
                    
                </DxColumn>

                <DxColumn data-field="password" caption="Κωδικός Πρόσβασης" :editor-type="'dxTextBox'" :cell-template="(cellElement) => cellElement.textContent = '••••••••'">
                    
                </DxColumn>
            </DxDataGrid>
        </DxItem>
          
        <DxItem title="Βασικες Εφαρμογες" id="app">
            <DxDataGrid
                :data-source="state.CustomStoreDetail_sims_employment_staff_access_office_basic_applications"
                :key-expr="id"
                :columns="columns"
                :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                :style="{ 'max-width': maxWidth }"
                ref="gridDetail_sims_employment_staff_access_office_basic_applications"
                :word-wrap-enabled="true"

                @exporting="onExporting"
                @rowClick="openForm"
                @init-new-row="onInitNewRow"
                @row-validating="onRowValidating">
            >
                <DxPaging :enabled="true" :page-size="5"/>

                <DxFilterRow :visible="false"/>
                <DxHeaderFilter :visible="true"/>
                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/>
                <DxGroupPanel :visible="false"/>
                <DxSorting mode="multiple"/>
                <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"/>

                <DxColumn
                    data-field="application_id"
                    caption="Εφαρμογή"
                    :hiding-priority="6"
                >
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                    <DxLookup :data-source="state.LookupCustomDS_sims_sw_applications" value-expr="id" display-expr="name"/>
                </DxColumn>

                <DxColumn data-field="username" caption="Όνομα Χρήστη">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="password" caption="Κωδικός Πρόσβασης" :editor-type="'dxTextBox'" :cell-template="(cellElement) => cellElement.textContent = '••••••••'">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="description" caption="Επιπλέον Πληροφορίες"></DxColumn>

                </DxDataGrid>
            </DxItem>

            <DxItem title="Web Εφαρμογες" id="web">
                <DxDataGrid
                    :data-source="state.CustomStoreDetail_sims_employment_staff_access_office_web_applications"
                    :key-expr="id"          
                    :columns="columns"
                    :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                    :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                    :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                    :style="{ 'max-width': maxWidth }"
                    ref="gridDetail_sims_employment_staff_access_office_web_applications"
                    :word-wrap-enabled="true"

                    @exporting="onExporting"
                    @rowClick="openForm"
                    @init-new-row="onInitNewRow"
                    @row-validating="onRowValidating">
                >
                    <DxPaging :enabled="true" :page-size="5"/>

                    <DxFilterRow :visible="false"/>
                    <DxHeaderFilter :visible="true"/>
                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/>
                    <DxGroupPanel :visible="false"/>
                    <DxSorting mode="multiple"/>
                    <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"/>

                    <DxColumn data-field="name" caption="Εφαρμογή">
                        <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                    </DxColumn>

                    <DxColumn data-field="url" caption="URL">
                        <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                    </DxColumn>

                    <DxColumn data-field="username" caption="Όνομα Χρήστη">
                        <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                    </DxColumn>

                    <DxColumn data-field="password" caption="Κωδικός" :editor-type="'dxTextBox'" :cell-template="(cellElement) => cellElement.textContent = '••••••••'">
                        <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                    </DxColumn>

                    <DxColumn data-field="description" caption="Επιπλέον Πληροφορίες"/>

                </DxDataGrid>
            </DxItem>

          <DxItem title="Συσκευες Hardware" id="devices">
            <DxDataGrid
                :data-source="state.CustomStoreDetail_sims_employment_staff_access_office_hardware_devices"
                :key-expr="id"
                :columns="columns"
                :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                :style="{ 'max-width': maxWidth }"
                ref="gridDetail_sims_employment_staff_access_office_hardware_devices"
                :word-wrap-enabled="true"

                @exporting="onExporting"
                @rowClick="openForm"
                @init-new-row="onInitNewRow"
                @row-validating="onRowValidating">
            >
                <DxPaging :enabled="true" :page-size="5"/>

                <DxFilterRow :visible="false"/>
                <DxHeaderFilter :visible="true"/>
                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/>
                <DxGroupPanel :visible="false"/>
                <DxSorting mode="multiple"/>
                <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"/>

                <DxColumn data-field="name" caption="Είδος Συσκευής">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="description" caption="Πληροφορίες Συσκευής"/>

                <DxColumn data-field="username" caption="Όνομα Χρήστη">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="password" caption="Κωδικός Πρόσβασης" :editor-type="'dxTextBox'" :cell-template="(cellElement) => cellElement.textContent = '••••••••'">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

            </DxDataGrid>
          </DxItem>

        </DxTabPanel>
      </div>

      <div class="dx-fieldset-header">Εξοπλισμός Υπαλλήλου</div>
      <div class="dx-fieldset">
            <DxDataGrid
                :data-source="state.CustomStoreDetail_sims_hw_VIEW"
                :key-expr="id"
                :columns="columns"
                :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                :style="{ 'max-width': maxWidth }"
                ref="gridDetail_sims_hw_VIEW"
                @exporting="onExporting"
                @rowClick="openForm"
                @init-new-row="onInitNewRow"
                @row-validating="onRowValidating">
            >
                <DxPaging 
                    :enabled="true" 
                    :page-size="5" 
                />

                <DxFilterRow :visible="false"/>
                <DxHeaderFilter :visible="false"/>
                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-info="true" :show-navigation-buttons="true"/>
                <DxGroupPanel :visible="false"/>
                <DxSorting mode="multiple"/>

                <DxColumn data-field="hardware_type" caption="Περιγραφή" sort-order="asc"/>
                <DxColumn data-field="code_model_name" caption="Κωδικός">
                </DxColumn>

            </DxDataGrid>
    </div>

        <div id="buttons">
            <DxToolbar>
                <DxItem 
                    location="after"
                    widget="dxButton"
                    @click="onClickSave"
                    :options="{
                    icon: 'save',
                    text: 'Αποθηκευση'
                }"
            >
                </DxItem>
                <DxItem 
                    location="after" 
                    widget="dxButton"
                    @click="onClickClose"
                    :options="{
                        icon: 'close',
                        text: 'Κλεισιμο'
                    }"
                >
                </DxItem>
            </DxToolbar>
        </div>
    </div>
</div>
</template>

<script>

  //Form
  const URL_SERVICE = OParameters.Host.URI + 'sims_employment_staff';
  
  const URL_SERVICE_LOOKUP_sims_buildings = OParameters.Host.URI + 'sims_buildings';
  const URL_SERVICE_LOOKUP_sims_directorate_services = OParameters.Host.URI + 'sims_directorate_services';
  const URL_SERVICE_LOOKUP_sims_directorate_service_department_offices = OParameters.Host.URI + 'sims_directorate_service_department_offices';
  const URL_SERVICE_LOOKUP_sims_directorate_service_departments = OParameters.Host.URI + 'sims_directorate_service_departments';
  
  const URL_SERVICE_LOOKUP_sims_employment_relationships = OParameters.Host.URI + 'sims_employment_relationships';
  const URL_SERVICE_LOOKUP_sims_employment_capacities = OParameters.Host.URI + 'sims_employment_capacities';
  const URL_SERVICE_LOOKUP_sims_telephony_numbers = OParameters.Host.URI + 'sims_telephony_numbers_VIEW';

  const URL_SERVICE_LOOKUP_sims_sw_applications = OParameters.Host.URI + 'sims_sw_applications';

    //Grids
  //sims_employment_staff_access_office_basic_applications
  const URL_SERVICE_sims_employment_staff_access_office_basic_applications = OParameters.Host.URI + 'sims_employment_staff_access_office_basic_applications';
  //sims_employment_staff_access_office_basic_applications
  const URL_SERVICE_sims_employment_staff_access_office_emails = OParameters.Host.URI + 'sims_employment_staff_access_office_emails';
  //sims_employment_staff_access_office_hardware_devices
  const URL_SERVICE_sims_employment_staff_access_office_hardware_devices = OParameters.Host.URI + 'sims_employment_staff_access_office_hardware_devices';
  //sims_employment_staff_access_office_printing
  const URL_SERVICE_sims_employment_staff_access_office_printing = OParameters.Host.URI + 'sims_employment_staff_access_office_printing';
  //sims_employment_staff_access_office_web_applications
  const URL_SERVICE_sims_employment_staff_access_office_web_applications = OParameters.Host.URI + 'sims_employment_staff_access_office_web_applications';
  //sims_hw_VIEW
  const URL_SERVICE_sims_hw_VIEW = OParameters.Host.URI + 'sims_hw_VIEW';

  //SEQUENCE
  const URL_SERVICE_seq_general_VIEW = OParameters.Host.URI + 'seq_general_VIEW';

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed } from 'vue';  
  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 
      DxRequiredRule,
      DxGroupItem,
      DxColCountByScreen,
  } from 'devextreme-vue/form'; 

  import DxDataGrid, {
      DxColumn,
      DxEditing, 
      DxPaging,
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxLookup,
  } from 'devextreme-vue/data-grid';

  import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';

  import DxToolbar from 'devextreme-vue/toolbar';

  export default {
      name: 'EmploymentStaffFormPage',
      components: {
          DxForm,
          DxLabel,
          DxSimpleItem,
          DxRequiredRule,
          DxDataGrid,
          DxColumn,
          DxEditing,
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow,
          DxHeaderFilter,
          DxLookup,
          DxToolbar,
          DxItem,
          DxColCountByScreen,
          DxGroupItem,
          DxTabPanel
      },
    data() {
        return {
            title: "Διαχείριση Τηλεφωνίας",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,,
        }
    },
    props: {
      employment_staff_id: {
          type: Number,
          required: true
      }
    },
    watch: {
          employment_staff_id(newValue, oldValue) {
                this.$emit('employment-staff-id-value-changed', newValue, oldValue);
                this.loadFormData(newValue);
      }
    },
    setup(props) {

        var  isDateBoxReadOnly = false

        const gridWidth = computed(() => '100%'); // set the width to 100%
        const maxWidth = computed(() => '800px'); // set the max-width to 800px
        const state = reactive({
            formData: {},

            LookupCustomDS_sims_buildings:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_buildings+ '?order=name'),
                byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_sims_buildings + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_employment_relationships: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_employment_relationships+ '?order=name'),
            }),

            LookupCustomDS_sims_employment_capacities: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_employment_capacities+ '?order=name'),
            }),

            LookupCustomDS_sims_directorate_service_department_offices: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_directorate_service_department_offices + '?order=name'),
            }),

            LookupCustomDS_sims_directorate_service_departments: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_directorate_service_departments + '?order=name')
            }),

            LookupCustomDS_sims_directorate_services: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_directorate_services + '?order=name'),
            }),

            LookupCustomDS_sims_sw_applications: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_sw_applications+ '?order=name'),
            }),
            
            LookupCascadeCustomDS_sims_telephony_numbers_VIEW: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_telephony_numbers + '?order=name&filter1=phone_number_type_id,eq,19&filter2=phone_number_type_id,eq,20&filter3=phone_number_type_id,eq,21' + '&filter=building_id,eq,' + state.formData.building_id),
                byKey: (key) => sendRequest(URL_SERVICE_LOOKUP_sims_telephony_numbers + "/" + encodeURIComponent(key)),
            }),
            
            LookupCustomDS_sims_telephony_numbers_filter_mobile: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_telephony_numbers + '?order=name&filter=phone_number_type_id,eq,22'),
            }),
            
            CustomStoreDetail_sims_hw_VIEW: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_sims_hw_VIEW + '?filter=assigned_employment_staff_id,eq,' + state.formData.id),
                //load: () => sendRequest(URL_SERVICE_sims_hw_VIEW),
                byKey: (key) => sendRequest(URL_SERVICE_sims_hw_VIEW + "/" + encodeURIComponent(key)),
            }),

            CustomStoreDetail_sims_employment_staff_access_office_web_applications: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_sims_employment_staff_access_office_web_applications + '?filter=employment_staff_id,eq,' + state.formData.id),
                insert: (values) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_web_applications, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_web_applications + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_web_applications + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_web_applications + "/" + encodeURIComponent(key)),
            }),

            CustomStoreDetail_sims_employment_staff_access_office_printing: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_sims_employment_staff_access_office_printing + '?filter=employment_staff_id,eq,' + state.formData.id),
                insert: (values) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_printing, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_printing + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_printing + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_printing + "/" + encodeURIComponent(key)),
            }),

            CustomStoreDetail_sims_employment_staff_access_office_hardware_devices: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_sims_employment_staff_access_office_hardware_devices + '?filter=employment_staff_id,eq,' + state.formData.id),
                insert: (values) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_hardware_devices, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_hardware_devices + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_hardware_devices + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_hardware_devices + "/" + encodeURIComponent(key)),
            }),

            CustomStoreDetail_sims_employment_staff_access_office_emails: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_sims_employment_staff_access_office_emails + '?filter=employment_staff_id,eq,' + state.formData.id),
                insert: (values) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_emails, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_emails + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_emails + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_emails + "/" + encodeURIComponent(key)),
            }),

            CustomStoreDetail_sims_employment_staff_access_office_basic_applications: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_sims_employment_staff_access_office_basic_applications + '?filter=employment_staff_id,eq,' + state.formData.id),
                insert: (values) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_basic_applications, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_basic_applications + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_basic_applications + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_employment_staff_access_office_basic_applications + "/" + encodeURIComponent(key)),
            }),


            CustomStore_seq_general_VIEW:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_seq_general_VIEW),
            }),

            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE),
                insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),
      });

      // 
      //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
      //
      const sendRequest = (url, method = 'GET', values = {}) => {
          //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
          if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                  return json.records;
                })
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
      };

      const sendRequestByKey = (url, method = 'GET') => {
          //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
          return fetch(url, {
                    method,
                    headers: {
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                })
                .then(response => response.json())
      };

      const saveData = async () => {
          // console.log('Save data');
          return await state.CustomStoreMain.update(state.formData.id, state.formData);
      };

      const insertData = async () => {
          console.log('Insert data');
          return await state.CustomStoreMain.insert(state.formData);
      };

      const clearForm = () => {
          state.formData = {};
      };

      var isGridValidated = true
      var isInsertMode = false
  
      onMounted(async () => {
            if (props.employment_staff_id != null) state.formData = await state.CustomStoreMain.byKey(props.employment_staff_id);
      });

      return {
          saveData,
          insertData,
          clearForm,
          gridWidth,
          maxWidth,
          isDateBoxReadOnly,
          state,
          isGridValidated,
          isInsertMode
      };
    },
    methods: {
        logEvent(/*eventName*/) {
            //console.log(eventName)
        },
        async loadFormData(id) {
            this.$refs.tabPanelGrids.instance.option('selectedIndex', 0);
            if (id != null) {
                this.isInsertMode = false
                this.state.formData = await this.state.CustomStoreMain.byKey(id)

                // cascade ips support
                const cascaderows = await this.state.LookupCascadeCustomDS_sims_telephony_numbers_VIEW.load()
                if (cascaderows) {
                    this.$refs.formMaster.instance.getEditor('phone_telephony_numbers_id').option('dataSource', cascaderows)
                }
            } else {
                this.getNextVal().then(value => {
                    this.isInsertMode = true

                    //this.state.formData = {entry_date: new Date().toISOString().split('T')[0]}
                    this.state.formData = {};
                    this.state.formData.id = value
                });
            }
            this.dataGrid_sims_employment_staff_access_office_emails.refresh()
            this.dataGrid_sims_hw_VIEW.refresh()
            
        },
        async onBuildingChange (e) {
            if (e.event && (e.event.type === 'dxclick' || e.event.type === 'keydown')) {
                //
                // Cascade Update dxSelectBox
                //
                if (e.value) {
                    console.log('onBuildingChange')
                    //const cascaderows = await this.state.LookupCascadeCustomDS_sims_network_ips_VIEW.load({filter: ['network_id', 'eq', e.value]})
                    const cascaderows = await this.state.LookupCascadeCustomDS_sims_telephony_numbers_VIEW.load()
                    
                    if (cascaderows) {
                        //this.$refs.formMaster.instance.updateData('network_ip_id', null)
                        this.$refs.formMaster.instance.getEditor('phone_telephony_numbers_id').option('dataSource', cascaderows)
                    }
                }
            }
        },
        async onDirectorateServicesChange (e) {
            if (e.event && (e.event.type === 'dxclick' || e.event.type === 'keydown')) {
                //
                // Cascade Update dxSelectBox
                //
                if (e.value) {
                    const cascaderows = await this.state.LookupCustomDS_sims_directorate_service_departments.load({filter: ['directorate_services_id', '=', e.value]})
                    if (cascaderows) {
                        this.$refs.formMaster.instance.updateData('directorate_service_department_id', null)
                        this.$refs.formMaster.instance.getEditor('directorate_service_department_id').option('dataSource', cascaderows)
                    }
                }
            }
        },
        async onDirectorateServicesDepartmentChange (e) {
            if (e.value === null) {
                this.$refs.formMaster.instance.updateData('directorate_service_department_office_id', null)
            }
            if (e.event && (e.event.type === 'dxclick' || e.event.type === 'keydown')) {
                //
                // Cascade Update dxSelectBox
                //
                if (e.value) {
                    const cascaderows = await this.state.LookupCustomDS_sims_directorate_service_department_offices.load({filter: ['directorate_service_department_id', '=', e.value]})
                    if (cascaderows) {
                        this.$refs.formMaster.instance.getEditor('directorate_service_department_office_id').option('dataSource', cascaderows)
                    }
                }
            }
        },
        onEmploymentRelationshipSelectionChange (e) {
            const selectedRecord = e.selectedItem;
            if (selectedRecord) {
                this.$refs.formMaster.instance.getEditor('enddate').option('disabled', (!selectedRecord.has_enddate_ind == true))
            }
        },

        async getNextVal() {
            //console.log('getNextVal')
            this.state.CustomStore_seq_general_VIEW.clearRawDataCache()
            let row = await this.state.CustomStore_seq_general_VIEW.load()
            return row[0].nextval
        },

        onClickSave: async function() {

            const formValidator = this.dataForm.validate();
            this.isGridValidated = true
            let formSaved = false

            if (formValidator.isValid) {
                if (!this.isInsertMode) {
                    await this.saveData()
                    formSaved = true
                } else {
                    await this.insertData()
                    formSaved = true
                }
            }

            this.$refs.tabPanelGrids.instance.option('selectedIndex', 0);
            await this.dataGrid_sims_employment_staff_access_office_emails.saveEditData();
            this.$refs.tabPanelGrids.instance.option('selectedIndex', 1);
            await this.dataGrid_sims_employment_staff_access_office_printing.saveEditData();
            this.$refs.tabPanelGrids.instance.option('selectedIndex', 2);
            await this.dataGrid_sims_employment_staff_access_office_basic_applications.saveEditData();
            this.$refs.tabPanelGrids.instance.option('selectedIndex', 3);
            await this.dataGrid_sims_employment_staff_access_office_web_applications.saveEditData();
            this.$refs.tabPanelGrids.instance.option('selectedIndex', 4);
            await this.dataGrid_sims_employment_staff_access_office_hardware_devices.saveEditData();

            if (this.isGridValidated && formSaved) this.$emit('closePopupEvent', true)

        },
        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },
        onInitNewRow(row) {
            row.data.employment_staff_id = this.state.formData.id;
            console.log(row.data.employment_staff_id)
        },        
        onRowValidating(e) {
            this.isGridValidated = this.isGridValidated && e.isValid;
        },
        async onSelectionChanged(e) {
            //console.log("Item clicked:", e);
            let gridinstance
            switch (e.addedItems[0].id) {
                case 'emails': gridinstance = this.$refs.gridDetail_sims_employment_staff_access_office_emails.instance; break
                case 'printing': gridinstance = this.$refs.gridDetail_sims_employment_staff_access_office_printing.instance; break
                case 'app': gridinstance = this.$refs.gridDetail_sims_employment_staff_access_office_basic_applications.instance; break
                case 'web': gridinstance = this.$refs.gridDetail_sims_employment_staff_access_office_web_applications.instance; break
                case 'devices': gridinstance = this.$refs.gridDetail_sims_employment_staff_access_office_hardware_devices.instance; break
            }
            //gridinstance.dataSource=[];
            if (gridinstance) {
                await gridinstance.refresh();
            }
            //e.addedItems[0].id = '';
        }
    },
    computed: {
        dataGrid_sims_employment_staff_access_office_web_applications: function() {
            return this.$refs.gridDetail_sims_employment_staff_access_office_web_applications.instance;
        },
        dataGrid_sims_employment_staff_access_office_printing: function() {
            return this.$refs.gridDetail_sims_employment_staff_access_office_printing.instance;
        },
        dataGrid_sims_employment_staff_access_office_hardware_devices: function() {
            return this.$refs.gridDetail_sims_employment_staff_access_office_hardware_devices.instance;
        },
        dataGrid_sims_employment_staff_access_office_emails: function() {
            return this.$refs.gridDetail_sims_employment_staff_access_office_emails.instance;
        },
        dataGrid_sims_employment_staff_access_office_basic_applications: function() {
            return this.$refs.gridDetail_sims_employment_staff_access_office_basic_applications.instance;
        },
        dataGrid_sims_hw_VIEW: function() {
            return this.$refs.gridDetail_sims_hw_VIEW.instance;
        },
        dataForm: function() {
            return this.$refs.formMaster.instance;
        },
    }
};
</script>

<style lang="scss">

</style>

<style scoped>

#form {
   margin-top: 25px;
}
.caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
.dx-fieldset-header {
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 4px;
}
</style>
