<template>
  <div class="content-block">
    <div class="footer">
      <div class="row" style="display: flex;">
        <div class="col-6" style="width: 50%;">
          Πνευματικά δικαιώματα © 2011-{{ new Date().getFullYear() }} {{ this.$appInfo.title }}.
          <br />
          Όλα τα εμπορικά σήματα ή σήματα κατατεθέντα αποτελούν ιδιοκτησία των αντίστοιχων κατόχων τους.
        </div>
          <div class="col-6" style="width: 50%; text-align: end;">
            <sup>Application version {{ this.$appInfo.version }}</sup>
            <br />
            <a href="https://sigmasoftware.gr/contact-us/" target="_blank" rel="noopener noreferrer">Επικοινωνία/Υποστήριξη</a>
          </div>
        </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.footer {
    display: block;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 24px;
}
</style>
